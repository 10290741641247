import React from 'react';
import Link from '../../UIs/Link/Link';

import './Icon.css';

const media = [
    {
        id: 'email',
        name: 'Email',
        darkImg: 'email-white.svg',
        lightImg: 'email-black.svg',
        url: 'mailto:takuma.kakehi@gmail.com'
    },
    {
        id: 'medium',
        name: 'Medium',
        darkImg: 'medium-white.svg',
        lightImg: 'medium-black.svg',
        url: 'https://medium.com/@takuma-kakehi'
    },
    {
        id: 'instagram',
        name: 'Instagram',
        darkImg: 'instagram-white.svg',
        lightImg: 'instagram-black.svg',
        url: 'https://www.instagram.com/takuma.kakehi/'
    },
    {
        id: 'dribbble',
        name: 'Dribbble',
        darkImg: 'dribbble-white.svg',
        lightImg: 'dribbble-black.svg',
        url: 'https://dribbble.com/tkakehi'
    },
    {
        id: 'twitter',
        name: 'Twitter',
        darkImg: 'twitter-white.svg',
        lightImg: 'twitter-black.svg',
        url: 'https://twitter.com/takuma_kakehi'
    }
]


const MediaIcon = (props) => {

    const content = media.filter(el => el.id === props.id)[0];

    if(typeof content === 'undefined') return [];

    const darkMode = props.darkMode ? true : false;
    const size = typeof props.size !== 'undefined' ? props.size : ' regular'

    let className = ('icon media ').concat(content.id);
    className = className.concat(darkMode ? ' dark' : ' light');
    className = className.concat(' '+size);

    const text = props.text===false ? [] :
        typeof props.text !== 'undefined' ? props.text : content['name'];

    return <Link className={className} href={content['url']} blank={true} >
        <img title={content['name']} alt={content['name']} src={`${process.env.PUBLIC_URL}/img/icons/${darkMode?content['darkImg']:content['lightImg']}`} />
        <div className='text'>{text}</div>
    </Link>
}

export default MediaIcon;