import React from 'react';
import Navigation from '../../../components/Navigation/Navigation';


const Intro = (props) => {

    return <div id='intro' className='intro-container'>
        <div className='left-container'>
            <h1 className='title'>Nice to meet you!</h1>
            <div className='subtitle'>I'm Takuma; An Experienced Product Owner and Interactive Designer.</div>
            <Navigation className={`${props.navState ? 'fixed-to-top' : 'intro'}`} />
        </div>
        <div className='right-container'></div>
    </div>
}

export default Intro;