import React from "react";

import Grids from "./Grids/Grids";



// Page
const Projects = (props) => {

    const filteredData = (db) => {
        if(typeof props.filters === 'undefined' || props.filters.filter(el => el['state']).length === 0) return db;

        // featured
        if(props.filters.filter(el => el.id === 'featured' && el['state']).length > 0){
            return db.filter(pj => pj['featured']).slice(0, 8);
        }

        // filter
        return db.filter(pj => {
            let pjTags = [];
            pj['categories'].forEach(el => {
                el['contents'].forEach(cat => {
                    pjTags.push(cat);
                })
            });
            return props.filters.filter(el => pjTags.filter(tag => el.id === tag.id).length > 0 && el['state']).length > 0;
        })
    }

    return props.data === false ?
        <div className='grid-outer-container'/>:
        <Grids 
            data={filteredData(props.data)}
        />
}





export default Projects;