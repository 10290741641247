import React, { useState } from 'react';



import Gradation from './Gradation/Gradation';
import Intro from './Intro/Intro';
import Projects from '../../components/Projects/Projects';
import Skills from './Skills/Skills';
import Researches from './Researches/Researches';

import './Top.css';
import Footer from '../../components/Footer/Footer';



const Top = (props) => {

    const [loaded, loadedState] = useState(false);
    const [scrollPassed, updateScrollPassedState] = useState(false);
    const [focusSection, updateFocusSection] = useState('intro');

    /* Scroll */
    const handleScroll = (event) =>{
        const scrollTop = event.currentTarget.scrollTop;

        const sections = {};
    
        // Intro
        sections['intro'] = {};
        sections['intro']['top'] = document.getElementById('intro').getBoundingClientRect().top;
        sections['intro']['height'] = document.getElementById('intro').offsetHeight;

        // Process and skills
        sections['process-and-skills'] = {};
        sections['process-and-skills']['top'] = document.getElementById('process-and-skills').getBoundingClientRect().top;
        sections['process-and-skills']['height'] = document.getElementById('process-and-skills').offsetHeight;
        
        if(sections['intro']['top'] < 600 && sections['intro']['top']+sections['intro']['height']-250 > -100){
            updateFocusSection('intro');
        }else if(sections['process-and-skills']['top'] < 600 && sections['process-and-skills']['top']+sections['process-and-skills']['height'] > 0){
            updateFocusSection('process-and-skills');
        }else{
            updateFocusSection('grids')
        }
        if(scrollTop > 450){
            if(!scrollPassed) updateScrollPassedState(true);
        }else{
            if(scrollPassed) updateScrollPassedState(false);
        }
    }

    setTimeout(() => {
        loadedState(true);
    }, 1000);

    return <div className={`page-top-container top ${loaded ? `loaded` : 'unloaded'} focus-${focusSection}`}>
        <Gradation />
        <div className={`scrollable-outer-container`} onScroll={handleScroll}>
            <Intro navState={scrollPassed}/>
            <Projects data={props.data}/>
            <Skills />
            <Researches />
            <Footer />
        </div>
    </div>
}

export default Top;