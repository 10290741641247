import React from 'react';
import Link from '../Link/Link';

import './Button.css';

// ClassName
const computeClassName = (props) => {

    const size = typeof props.size !== 'undefined' ? props.size : 'regular';
    // Class names
    let className = 'button';
    // size
    className = className.concat(' '+size)

    if(typeof props.className !== 'undefined'){
        className = className.concat(' '+props.className)
    }

    if(typeof props.options !== 'undefined'){
        className = className.concat(' options')
    }

    return className;
}


// Icons
const renderFrontIcon = (props) => {
    if(typeof props.icon === 'undefined') return [];
    const title = typeof props.icon['title'] !== 'undefined' ? props.icon['title'] : props.text;
    return <img className='front-icon' title={title} alt={title} src={props.icon['src']}/>
}
const renderBlank = (props) => {
    if(!props.blank) return [];
    return <img className='blank-icon' title={'Open new tab'} alt={'Open new tab'} src={`${process.env.PUBLIC_URL}/img/icons/new-window.svg`}/>
}





const Button = (props) => {

    // Class names
    let className = computeClassName(props);

    let content = [];
    content.push(renderFrontIcon(props));
    content.push(<div key='main-text' className='text'>{props.text} {renderBlank(props)}</div>);

    // Button type: Link
    if(typeof props.href !== 'undefined'){
        return <Link className={className} href={props.href} blank={props.blank}>
            {content}
        </Link>
    }

    if(typeof props.callback !== 'undefined'){
        return <div className={className} onClick={props.callback}>
            {content}
        </div>
    }

    return <div className={className}>
            {content}
        </div>
    
}


export default Button;