
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom';

import { getUrlParams } from '../components/Methods/UniversalMethods';
import { loadProjectsData } from '../components/Methods/ProjectsMethods';
import Top from './Top/Top';
import AllProjects from './AllProjects/AllProjects';
import Project from './Project/Project';

import '../styles/style.css';
import Contact from './Contact/Contact';
import Footer from '../components/Footer/Footer';





const App = () => {   

    const [data, updateData] = useState(false);
    const [accessKey] = useState(getUrlParams('key')); // URL Params
   
    /* 
        Retrieve basedata
    */
    useEffect(() => {

        const fetchData = async () => {
            let sd = await loadProjectsData();

            // filter by access keys
            if(!accessKey.includes('b8Mx2l4RgiGr')){
                sd = sd.filter(list => !list['private']);
            }

            updateData(sd)
        }

        fetchData()
          .catch(console.error);;

      }, [accessKey]);

      
    return <BrowserRouter>
        <Routes>
           {/* Top page */}
           <Route exact path="/" element={<Top data={data}/>} />
           {/* Projects */}
           <Route exact path="/projects" element={<AllProjects data={data}/>} />
           {/* Project */}
           <Route exact path="/project/:id" element={<Project data={data}/>} />
           {/* Contacts */}
           <Route exact path="/contact" element={<Contact />} />

           <Route exact path="/" element={<Footer />} />
          
        </Routes>
        
    </BrowserRouter>
}

export default App;
