import React from 'react';
import { Title } from '../Title/Title';

import './PageHeader.css';

const PageHeader = (props) => {

    let className = 'page-header-container';
    if(typeof props.className !== 'undefined') className = className.concat(' ' + props.className)

    const style = typeof props.style !== 'undefined' ? props.style : {};
    
    return <div id={`project-top`} className={className} style={style}>
        <div className={'bg-img'} style={props.bgImgStyle}>
            <div className='overlay' />
            <div className={`page-header-inner-container`}>
                <Title header={props.header} />
                {/* Children */}
                {typeof props.children !== 'undefined' ? props.children : ''}
            </div>
        </div>
    </div>
}

export default PageHeader;