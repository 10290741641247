import React from "react";

import Link from "../../../UIs/Link/Link";
import { Tag } from "../../../UIs/Tag/Tag";
import { GetCategoriesByIds } from "../../Methods/CategoriesMethods";

import { convertToId, formUrlParams } from "../../Methods/UniversalMethods";

import './Grids.css';

const GridCard = (props) => {

    const imgs = props.content.thumb.replace(' ', '').split(',').map((name, index) => {
        return {
            id: index === 0 ? 'hero-img' : 'hover-img',
            src: '/img/projects/'+name
        }
    });

    const filters = 
        GetCategoriesByIds(props.content['filters'].replaceAll(' ', '').split(','))
            .filter((fl) => fl['primary'])
            .map((fl) => <Tag key={fl.id} className={fl.id} text={fl['name']} />);

    return <Link className={`grid-card-container`} href={props.content['destination'].length === 1 ? `/project/${props.content['destination']}${formUrlParams()}` : props.content['destination'][1]} blank={props.content['destination'].length === 2}>
            <div className='hero-img-container'>
                {imgs.map(img => <div key={img.id} className='img-inner-container'><img className={img.id} title={props.content['title']} alt={props.content['title']} src={img.src}/></div>)}
            </div>
            <div className={`card-content-container`}>
                <h3 className='title'>
                    {props.content['title']}
                    {props.content['destination'].length === 2 ? <img className='blank-icon' title={'Open new tab'} alt={'Open new tab'} src={`${process.env.PUBLIC_URL}/img/icons/new-window.svg`}/> : ''}</h3>
                <p className='description'>{props.content['short-description']}</p>
                <div className='tags-container'>{filters}</div>
            </div>
        </Link>
}


const Grids = (props) => {

    return <div className='grid-outer-container'><div className='grids-container'>
        {props.data.map(el => <GridCard key={convertToId(el['title'])} content={el}/>)}
    </div></div>
}

export default Grids;