import React from "react";

import './Tag.css';

export const Tag = (props) => {

    // Classnames
    let classNames = [];
    if(typeof props.className !== 'undefined') classNames.push(props.className);
    if(typeof props.callback !== 'undefined') classNames.push('clickable');
    let className = '';
    classNames.forEach((el, index) => {
        className = className.concat(el);
        if(index < classNames.length - 1) className = className.concat(' ');
    })

    const element = <div className={`tag ${className}`}>{props.text}</div>

    if(typeof props.callback !== 'undefined'){
        // Callback
        return <div onClick={props.callback} className={`tag-outer-container`}>{element}</div>
    }else{
        return element;
    }
    
}