import React from 'react';


import MediaIcon from '../../components/Icons/MediaIcon';
import Navigation from "../../components/Navigation/Navigation";
import Footer from '../../components/Footer/Footer';


import './Contact.css';



const Contact = (props) => {

    return <div className={`page-top-container contact`}>
        <Navigation className={`fixed-to-top scrolled`} />
        <div className={`scrollable-outer-container`}>
            <div className="contact-methods-container">
                <div className="main-text">
                    <h1 className="v-padding-l"><span className='text-yellow'>Happy</span> to get to know <span className='text-red'>you</span>, and <span className='text-blue'>your projects</span></h1>
                </div>
                <div className="media-container">
                    <MediaIcon id='email' size='xlarge'/>
                    <MediaIcon id='medium' size='xlarge'/>
                    <MediaIcon id='dribbble' size='xlarge'/>
                </div>
            </div>
            <Footer />
        </div>
    </div>
}

export default Contact;