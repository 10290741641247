import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Projects from '../../components/Projects/Projects';

import PageHeader from '../../components/PageHeader/PageHeader';
import Navigation from '../../components/Navigation/Navigation';
import { Tag } from '../../UIs/Tag/Tag';
import { GetUngroupedCategories } from '../../components/Methods/CategoriesMethods';

import './AllProjects.css';
import Footer from '../../components/Footer/Footer';
import { formUrlParams, getUrlParams } from '../../components/Methods/UniversalMethods';

const AllProjects = (props) => {

    const navigate = useNavigate();

    const [loaded] = useState(false);
    const [filters, updateFilters] = useState(
        GetUngroupedCategories()
            .filter(cat => cat['primary'])
            .map(el => {let newEl = {...el}; newEl['state'] = getUrlParams('filters').filter(fl => fl === newEl.id).length > 0 ? true : false; return newEl;}))

    const renderHeaderChildren = () => {

        const tagWasClicked = (id) => {
            
            const addParams  = getUrlParams('filters').filter(fl => fl === id).length === 0 ? [id] : [];
            const removeParams  = getUrlParams('filters').filter(fl => fl === id).length > 0 ? [id] : [];

            const newUrl = `${process.env.PUBLIC_URL}/projects${formUrlParams([
                {
                    paramId: 'filters',
                    addParams: addParams,
                    removeParams: removeParams
                }
            ])}`

            navigate(newUrl)

            updateFilters([...filters].map(el => {
                if(el.id !== id){
                    return el;
                }else{
                    let newEl = {...el};
                    newEl['state'] = !el['state'];
                    return newEl;
                }
            }));
        }

        return <div className='tags-container'>
            {filters.map(el => <Tag key={el.id} className={`${el.id} ${el['state'] ? 'selected' : 'unselected'}`} text={el['name']} callback={()=>tagWasClicked(el.id)}/>)}
        </div>
    }


    return <div className={`page-top-container all-projects ${loaded ? `loaded` : 'unloaded'}`}>
        <Navigation className={`${'fixed-to-top'}`} />
        <div className={`scrollable-outer-container`}>
            <PageHeader 
                header='All projects'
                children={renderHeaderChildren()}
            />
            <Projects data={props.data} filters={filters}/>
            <Footer />
        </div>
        
    </div>
}

export default AllProjects;