import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { convertToId } from '../../components/Methods/UniversalMethods';
import { loadProjectDataById } from '../../components/Methods/ProjectsMethods';

import {Tag} from '../../UIs/Tag/Tag';
import PageHeader from '../../components/PageHeader/PageHeader';
import Navigation from '../../components/Navigation/Navigation';

import './Project.css';
import Button from '../../UIs/Button/Button';
import Footer from '../../components/Footer/Footer';

const MainBody = (props) => {

    if(!Array.isArray(props.contents)) return <div className='main-body-container'/>;

    return <div className='main-body-container'>
        {props.contents.map((el,index) => {
            let rtnObj = [];
            rtnObj.push(<div key={`content-${index}`} className='project-description' dangerouslySetInnerHTML={{ __html: el['content']}} />);
            if(typeof el['collapsible-module'] !== 'undefined' && el['collapsible-module'] !== null) rtnObj.push(<ProjectAccordion key={`collapsible-${index}`}><div dangerouslySetInnerHTML={{__html: el['collapsible-module']}} /></ProjectAccordion>);
            return rtnObj;
        })}
    </div>
}

const ProjectAccordion = (props) => {

    let [expanded, manageAccordion] = useState(false);

    let className = 'accordion';
    if(typeof props.className !== 'undefined') className = className.concat(' ' + props.className)

    return <div className={className}>
        <div className='collapsible-cta' onClick={() =>  manageAccordion(!expanded)}>
            <h5>{expanded ? 'Collapse' : 'Expand for detail'}</h5>
            <div className={`arrow ${expanded ? 'up' : 'down'}`}/>
        </div>
        <div className={`collapsible ${expanded ? 'shown' : 'hidden'}`}>
            {props.children}
            <div className='collapsible-cta' onClick={() =>  manageAccordion(!expanded)}>
                <h5>Collapse</h5>
                <div className={`arrow up`}/>
            </div>
        </div>
    </div>
}

const Categories = (props) => {
    

    if(!Array.isArray(props['categories']) || props['categories'].length === 0) return [];


    return <div className='categories-container'>
        {props['categories'].map(type => 
            {return <div key={type.id} className={`categories-type-container ${convertToId(type['name'])}`}>{type['contents'].map(el => 
                <Tag key={`type-${type.id}-${el.id}`} className={`tag category`} text={el['name']} />
            )}</div>}
        )}
    </div>
}



const Project = (props) => {

    const [pageId] = useState(useParams().id);
    const [data, updateData] = useState(false);

    const [scrollPassed, updateScrollPassedState] = useState(false);

    const [expandImageState, updateExpandImageState] = useState(false);
    
    /* 
        Retrieve data
    */
    useEffect(() => {

        if(!data && props.data !== false){
            let projectContextData = props.data.filter(project => project['destination'][0] === pageId)[0]
            updateData(projectContextData);
            
            const fetchData = async () => {
                const id = props.data.filter(el => el['destination'][0] === pageId)[0]['snippet'];
                const sd = await loadProjectDataById(id);
                updateData({...projectContextData, ...sd})
            }
    
            fetchData()
                .catch(console.error);
        }
        
        

    }, [props, props.data, data, pageId]);

    /* 
        Scroll
    */
    const handleScroll = (event) =>{     
        const scrollTop = window.pageYOffset;
        if(scrollTop > 600){
            if(!scrollPassed) updateScrollPassedState(true);
        }else{
            if(scrollPassed) updateScrollPassedState(false);
        }
    }
    window.addEventListener('scroll', handleScroll);

    // BG Image style
    const bgImgStyle = !data || typeof data['hero-image'] === 'undefined' ? {} : 
    {
        background: `url(${process.env.PUBLIC_URL}/img/projects/${data['hero-image']['content']}) no-repeat`
    };
    const pageHeaderClassName = !data || typeof data['hero-image'] === 'undefined' ? undefined : `${data['snippet']} bg-img`;


    return <div className={`project-container ${expandImageState?'expanded-img':'shrank-img'}`}>
        <Navigation className={`fixed-to-top ${data['snippet']} ${scrollPassed ? 'scrolled' : 'top'}`} />
        <div className={`scrollable-outer-container`}>
            <PageHeader 
                header={data['title']}
                children={<div className='description'>
                    <div>{data['short-description']}</div>
                    <Categories categories={data['categories']}/>
                </div>}
                className={pageHeaderClassName}
                bgImgStyle={bgImgStyle}
            />
            <MainBody contents={data['main-body']}/>
        </div>
        <Button size='small' className='expand-image' text={expandImageState ? 'Shrink images' : 'Enlarge images'} callback={()=>updateExpandImageState(!expandImageState)}/>
        <Footer />
    </div>
}

export default Project;