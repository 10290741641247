import React from 'react';
import Link from '../../../UIs/Link/Link';

import './Skills.css';

const processes = [
    {
        title: '1. Comprehend and Be on the Same Page',
        description: '<p>Free-hand brainstorming tools are an effective way to communicate project objectives and requirements, as <b>they engage the team and gradually expose narratives in real-time</b>, making them more understandable than detailed documents or PowerPoint presentations.</p>',
        skills: [
            {
                name: 'Invision (Free hand)',
                img: '/img/skills/invision.svg'
            },
            {
                name: 'miro',
                img: '/img/skills/miro.svg'
            }
        ]
    },
    {
        title: '2. Make Abstract Ideas Tangible',
        description: '<p>Wireframes are typically considered as blueprints for web and mobile layouts, but they are also effective in <b>enabling productive communication</b> during the early stages of a project when ideas are still abstract. By making abstract ideas tangible, wireframing exercises can help teams to identify key discussion points and actionable tasks.</p>',
        skills: [
            {
                name: 'Figma',
                img: '/img/skills/figma.png'
            },
            {
                name: 'Sketch App',
                img: '/img/skills/sketch-app.png'
            }
        ]
    },
    
    {
        title: '3. Interactive and In-Depth Prototypes',
        description: '<p>While wireframes are effective for driving conceptual conversations, <b>interactive prototypes are helpful in bringing focus to details</b>. They are necessary for exploring detailed usability options and receiving constructive feedback from users outside of the project team.</p><p>As the design process matures, coded prototypes may also be effective in testing navigations, responsive structures, and information layouts. This allows designers to interact with a more realistic data structure while designing.</p>',
        link: {
            href: '/project/speeda-edge-react-proto',
            text: 'Case study: EDGE - React Prototype'
        },
        skills: [
            {
                name: 'React',
                img: '/img/skills/react.png'
            },
            {
                name: 'Next.js',
                img: '/img/skills/next-js.svg'
            },
            {
                name: 'CSS',
                img: '/img/skills/css-3.svg'
            },
            {
                name: 'Principle',
                img: '/img/skills/principle.svg'
            }
        ]
    },
    {
        title: '4. Make Designs Executable',
        description: "I don't leave projects as just concepts, sketches, or mere talk. With years of experience as an independent contributor at different organizations, I also enjoy making projects more colorful and fun! I explore and execute ways to produce images and videos that can enhance your projects.",
        skills: [
            {
                name: 'Photoshop',
                img: '/img/skills/photoshop.svg'
            },
            {
                name: 'After Effects',
                img: '/img/skills/after-effects.svg'
            },
            {
                name: 'Lotti',
                img: '/img/skills/lotti.svg'
            }
        ]
    },
    {
        title: '5. Be Analytical, and Be Productive',
        description: '<p>I believe improving productivity is one of core pillars for successful products. Fail fast, and improve upon. Being close to analytical tools helps <b>fine-tune project directions, and stay design process productive.</b></p><h3>... then go back to 1 with new hypothesis.</h3>',
        skills: [
            {
                name: 'Google Analytics',
                img: '/img/skills/google-analytics.png'
            },
            {
                name: 'Tableau',
                img: '/img/skills/tableau.svg'
            }
        ]
    },
    {
        title: '... and also have fun on free times',
        description: 'I began my design career with architectural design courses, where I learned the reasoning behind designs. Around the same time, I became fascinated by the speed of receiving feedback and iterating in interaction design. Since then, I have continued to explore designs in 3D space, generative coding, to graphic design to make everything a little more fun!',
        skills: [
            {
                name: 'Unity3D',
                img: '/img/skills/unity3d.svg'
            },
            {
                name: 'Processing',
                img: '/img/skills/processing.svg'
            },
            {
                name: 'Blender',
                img: '/img/skills/blender.png'
            },
            {
                name: 'D3',
                img: '/img/skills/d3.svg'
            },
            {
                name: 'Cinema 4D',
                img: '/img/skills/cinema4d.png'
            },
            {
                name: 'Rhinoceros 3D',
                img: '/img/skills/rhinoceros3d.svg'
            },
            {
                name: 'Autocad',
                img: '/img/skills/autocad.png'
            },
            {
                name: 'Next.js',
                img: '/img/skills/next-js.svg'
            },
            {
                name: 'Maya',
                img: '/img/skills/maya.png'
            },
        ]
    },
]

const SkillIcon = (props) => {

    return <div className='skill'>
        <img title={props.skill['name']} alt={props.skill['name']} src={props.skill['img']} />
    </div>
}

const Skills = (props) => {
    return <div id="process-and-skills" className="top-section-container skills-section-container">
        <div className='title-container'>
            <h1 className='title'>Process and Skills</h1>
            <div className='subtitle'>My skills and their utilization across project stages</div>
        </div>
        <div className='skills-segments-container'>
            {
                processes.map((process, index) => <div key={process['title']} className={`skills-by-type indent-${index}`}>
                    <div className='process-explainer-container'>
                        <h2 className='process-title'>{process['title']}</h2>
                        <p className='process-description' dangerouslySetInnerHTML={{ __html: process['description']}} />
                        {typeof process['link'] !== 'undefined' ?  <Link className='text-link' href={process['link']['href']} blank={true}>{process['link']['text']}</Link>: ''}
                    </div>
                    <div className='skills'>
                        {process['skills'].map(skill => <SkillIcon key={skill['name']} skill={skill}/>)}
                    </div>
                </div>

                )
            }
           
        </div>
    </div>
}

export default Skills;