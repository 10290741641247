import React from 'react';

import MediumCard from '../../../components/Cards/MediumCard/MediumCard';
import Button from '../../../UIs/Button/Button';


const researches = [
    {
        title: 'Extra-gamified: why are some apps so satisfying?',
        description: 'According to Statista, an average of 6,140 apps were released in Play Store everyday in the first quarter of 2018. Yet, 77% of users never returned to the app after ...',
        href: 'https://uxdesign.cc/extra-gamified-why-are-some-apps-so-satisfying-7ae8df998394',
        img: '/img/researches/grand-theft-auto-vice-city.jpeg'
    },
    {
        title: 'White on black or black on white? The pros and cons of Dark Mode',
        description: 'There has been a lot of talk and leaks about Dark Mode for the upcoming WWDC 2019 (Exclusive: Screenshots reveal iOS 13 Dark Mode, new Reminders app, more ...',
        href: 'https://medium.com/p/3439e37d6c6c',
        img: '/img/researches/white-on-black-or-black-on-white.png'
    },
    {
        title: 'Subscription is everywhere: user flow analysis for 10 business typesa',
        description: 'Subscription business is everywhere. For a user, it’s simply cheaper to use or experience a product without the high cost of owning it. For a company ...',
        href: 'https://medium.com/p/e0d54ae293c9',
        img: '/img/researches/subscription-is-everywhere.png'
    },
    {
        title: 'The power of familiarity in Design: skeuomorphic triggers and personified machines',
        description: 'In 2007, right after Steve Jobs first revealed the iPhone in a presentation focusing on Apple’s intent to reinvent the phone, Jobs opened the conversation on the ...',
        href: 'https://uxdesign.cc/the-power-of-familiarity-in-design-skeuomorphic-triggers-and-personified-machines-7fb66ba8bf98',
        img: '/img/researches/skeuomorphic.png'
    },
    {
        title: 'Is the chatbot a hype of the past? What are the key takeaways?',
        description: 'Facebook debuted its Messenger bot platform in April 2016, and Google announced its Google Assistant in May 2016. During this time, I was on the team that launched ...',
        href: 'https://uxdesign.cc/is-the-chatbot-a-hype-of-the-past-what-are-the-key-takeaways-3922a6895500',
        img: '/img/researches/chat-bot.jpeg'
    },
    {
        title: 'Data Visualization Infographics v.s. Products',
        description: 'I was fortunate to participate in a panel discussion concerning data science hosted by Toptal. As a designer, my contribution was to share examples on how data ...',
        href: 'https://medium.com/nyc-design/data-visualization-infographics-v-s-products-429bc31c839d',
        img: '/img/researches/data-visualization.png'
    }
]

const Researches = (props) => {
    return <div id="researches" className="top-section-container researches-section-container">
        <div className='title-container'>
            <h1 className='title'>Research</h1>
            <div className='subtitle'>Tools and languages I often use to design</div>
        </div>
        <div className='researches-segments-container'>
            {
                researches.map((research, index) => <MediumCard 
                    key={research['title']} 
                    content={{
                        title: research['title'],
                        description: research['description'],
                        img: research['img'],
                        href: research['href']
                    }} href={research['href']} blank={true} />
                )
            }
           
        </div>
        <div className='align-container center'><Button text='View more research' href={`https://takuma-kakehi.medium.com/`}blank={true}/></div>
        
    </div>
}

export default Researches;