import React from 'react';

import { OpenLink } from '../../components/Methods/UniversalMethods';

import './Link.css';

const Link = ({ id, className, href, history, blank, children, callBack }) => {

    const onClick = (event) => {

        // if users used right mouse button for options, return here (normal <a> action)
        if (event.metakey || event.ctrlKey){
            return;
        }
        
        // make sure it doesn't trigger the default action
        event.preventDefault();

        if(typeof callBack !== 'undefined') {
            callBack(id)
        }

        

        if(typeof href !== 'undefined'){
            if(blank){ 
                // open new window if blank is true
                window.open(href);
            }else{
                OpenLink(href, history);
            }
        }
        
         
    };


    if(typeof href !== 'undefined'){
        return blank ? <a id={typeof id !== "undefined" ?  id : ''} href={href} target='_blank' rel="noreferrer" className={`link ${typeof className !== 'undefined' ? className : ''}`} >
            {children}
        </a> : <a id={typeof id !== "undefined" ?  id : ''} href={href} rel="noreferrer" className={`link ${typeof className !== 'undefined' ? className : ''}`} >
            {children}
        </a> 
    }

    return( 
        <div id={typeof id !== "undefined" ?  id : ''} onClick={onClick} className={`link ${typeof className !== 'undefined' ? className : ''}`} >
            {children}
        </div>
    );
};

export default Link;