import React from 'react';

import Link from '../../../UIs/Link/Link';


import './../Card.css';

const MediumCard = (props) => {
    
    if(typeof props.content === 'undefined') return [];

    return <Link key={props.content['title']} className={`card medium-card`} href={props.content['href']} blank={true}>
        <div className='card-content-container'>
            <div className='img-container'>
                <img title={props.content['title']} alt={props.content['title']} src={props.content['img']}/>
            </div>
            <h3 className='title'>{props.content['title']}</h3>
            <p className='description'>{props.content['description']}</p>
            <Link className='profile' href='https://medium.com/@takuma-kakehi' blank={true}><img title='Medium Logo' alt='Medium Log' src='/img/researches/medium.png'/><img title='author' alt='title' src='/img/researches/takuma-kakehi.jpeg'/>Takuma Kakehi</Link>
        </div>
    </Link>
}

export default MediumCard;