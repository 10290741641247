import spreadsheet from "../../api/spreadsheet";

import { convertToId } from "./UniversalMethods";
import { GetGroupedCategoriesByIds } from "./CategoriesMethods";

const formatProjectData = (sourceData) => {

    let rtnData = [];
    sourceData.rows.slice(1, sourceData.rows.length).forEach(row => {
        let newObj = {};
        sourceData.rows[0].c.forEach((el, index) => {
            newObj[convertToId(el['v'])] = row.c[index] !== null && row.c[index].v !== null ? row.c[index].v : false;
            if(newObj[el.v] === 'y') newObj[el.v] = true;
        });

        //destination
        newObj['destination'] = newObj['destination'].split(',');

        //filters to categories
        newObj['categories'] = GetGroupedCategoriesByIds(newObj['filters'].replaceAll(' ', '').split(','));
        rtnData.push(newObj);
    });

    return rtnData;
    
}


export const loadProjectsData = async () => {
    const response = await spreadsheet.get('1fEYk_Ahztftf8-d7SAyrlZ-DXIDMrOCBzcWKnIYz3-A/gviz/tq?tqx=out:json', {
        params: { query: '' }
    });
    const sourceData = JSON.parse(response.data.substr(47).slice(0, -2)).table;
    return formatProjectData(sourceData);
}


export const loadProjectDataById = async (id) => {
    const response = await spreadsheet.get('1fEYk_Ahztftf8-d7SAyrlZ-DXIDMrOCBzcWKnIYz3-A/gviz/tq?tqx=out:json&sheet='+id, {
        params: { query: '' }
    });
    const data = JSON.parse(response.data.substr(47).slice(0, -2)).table.rows;
    const content = {};
    let mainBody = [];
    data.forEach((el, index) => {
        const item0 = convertToId(el['c'][0]['v']);
        const title1 = convertToId(data[0]['c'][1]['v']);
        const title2 = typeof data[0]['c'][2] !== 'undefined' ? convertToId(data[0]['c'][2]['v']) : undefined;
        
        if(index > 0){
            content[item0] = {};
            if(3 > index){
                // Name
                if(index < 2){
                    content[item0][title1] = el['c'][1]['v'];
                    if(typeof title2 !== 'undefined') content[item0][title2] = el['c'][2]['v'];
                }
                
            }else{
                let bodyObj = {};
                bodyObj[title1] = el['c'][1]['v'];
                if(typeof title2 !== 'undefined') bodyObj[title2] = el['c'][2]['v'];
                mainBody.push(bodyObj)
            }
        }
    });
    content['main-body'] = mainBody;
    return content;
}