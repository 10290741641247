import { convertToId } from "./UniversalMethods";





export const categories = [
    {
        name: 'Roles',
        contents: [
            {
                id: 'product-director',
                name: 'Product Director',
                primary: true
            },
            {
                id: 'design-management',
                name: 'Design Management',
                primary: true
            },
            {
                id: 'interaction-design',
                name: 'Interaction Design',
                primary: true
            },
            {
                id: 'product-design',
                name: 'Product Design',
                primary: true
            },
            {
                id: 'product-management',
                name: 'Product Management',
                primary: true
            },
            {
                id: 'project-management',
                name: 'Project Management'
            },
            {
                id: 'ux-design',
                name: 'UX Design',
                primary: true
            },
            {
                id: 'responsive-web',
                name: 'Responsive Web',
                primary: true
            },
            {
                id: 'mobile-app',
                name: 'Mobile App',
                primary: true
            },
            {
                id: 'design-system',
                name: 'Design System'
            },
            {
                id: 'art-direction',
                name: 'Art Direction'
            },
            {
                id: 'immersive',
                name: 'Immersive',
                primary: true
            },
            {
                id: 'vr',
                name: 'VR'
            },
            {
                id: 'touch-display',
                name: 'Touch Display'
            },
            {
                id: 'chat-bot',
                name: 'Chat bot'
            },
            {
                id: 'coded-prototype',
                name: 'Coded Prototype'
            },
            {
                id: 'fun',
                name: 'Fun',
                primary: true
            }
        ]
    },
    {
        name: 'Skills',
        contents: [
            
            {
                id: 'figma',
                name: 'Figma'
            },
            {
                id: 'react',
                name: 'React'
            },
            {
                id: 'css',
                name: 'CSS'
            },
            {
                id: 'invision',
                name: 'inVision'
            },
            {
                id: 'miro',
                name: 'Miro'
            },
            {
                id: 'unity3d',
                name: 'Unity3D'
            },
            {
                id: 'cinema4d',
                name: 'Cinema4D'
            },
            {
                id: 'open-frameworks',
                name: 'Open Frameworks'
            },
            {
                id: 'processing',
                name: 'Processing'
            },
            {
                id: 'actionscript',
                name: 'Action Script'
            }
        ]
    }
]

// Retrieve all
export const GetUngroupedCategories = () => {
    let rtnObj = [];
    categories.forEach(catGroup => {
        catGroup['contents'].forEach(cat => {
            rtnObj.push(cat);
        })
    })
    return rtnObj;
}

// Retrieve by Ids
export const GetCategoriesByIds = (ids) => {
    let rtnArr = [];
    rtnArr = ids.map(id => {
        let category = id;
        categories.forEach(cat => {
            const tempCat = cat['contents'].filter(con => con.id === convertToId(id))[0];
            if(typeof tempCat !== 'undefined'){
                category = {...tempCat};
                category['type'] = cat['name'];
            }
        });
        return category;
    });
    return rtnArr
}

export const GetGroupedCategoriesByIds = (ids) => {
    if(typeof ids === 'undefined') return [];

    let rtnArr = [...categories].map(cat => {
        let newCat = {...cat};
        newCat['contents'] = {...cat}['contents'].filter(el => ids.filter(id => el.id === convertToId(id)).length > 0);
        return newCat;
    }).filter(cat => cat['contents'].length > 0);
    return rtnArr
}