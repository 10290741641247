import React from 'react';
import Link from '../../UIs/Link/Link';
import MediaIcon from '../Icons/MediaIcon';

import './Footer.css';

const Footer = (props) => {
    return <div className='footer-container'>
        <div className='footer-content-container'>
            <div className='left-container caption'>
                <Link className='h5' href={`${process.env.PUBLIC_URL}/`}>OPTMYST</Link>
                <div className='subtitle'>Takuma Kakehi: An Experienced Product Owner and Interactive Designer.</div>
            </div>
            <div className='right-container media'>
                <MediaIcon id='email' size='mini' text={false} darkMode={true}/>
                <MediaIcon id='medium' size='mini' text={false} darkMode={true}/>
                <MediaIcon id='dribbble' size='mini' text={false} darkMode={true}/>
                <MediaIcon id='instagram' size='mini' text={false} darkMode={true}/>
                <MediaIcon id='twitter' size='mini' text={false} darkMode={true}/>
            </div>
        </div>
    </div>
}

export default Footer;