import React from 'react';
import { useLocation } from 'react-router-dom';

import Link from '../../UIs/Link/Link';
import { formUrlParams } from '../Methods/UniversalMethods';

import './Navigation.css';

const Navigation = (props) => {

    console.log(useLocation());
    const location = useLocation();

    return <ul className={`navigation-container ${typeof props.className !== 'undefined' ? props.className : ''}`}>
        <ul className='internal-stack left-stack'>
            <NavItem href={`/`} text={'Home'} selected={
                !location['pathname'].includes('/projects')&&
                !location['pathname'].includes('/contact')
            } />
            <NavItem href={`/projects`} text={'All Projects'} selected={location['pathname'].includes('/projects')} />
            <NavItem href={`/contact`} text={'Contact'} selected={location['pathname'].includes('/contact')} />
        </ul>
        
    </ul>
}

const NavItem = (props) => {

    let className='';
    if(props['selected'])className=className.concat('selected');

    return <li className={className}><Link href={`${process.env.PUBLIC_URL}${props['href']}${formUrlParams()}`}>{props['text']}</Link></li>
}

export default Navigation;

