export const convertToId = (input) => {
    return typeof input === 'string' ? input.replaceAll(' ', '-').replaceAll('&', 'and').toLowerCase() : input;
}


// simply open the link
export const OpenLink = (href, history, interactionId) => {
    if(typeof history !== 'undefined'){
        const prevLocation = history.location;
        history.push(href, {'prevLocation': prevLocation, 'interactionId': interactionId});
    }else{
        window.history.pushState({}, '', href);
        const navEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navEvent);  
    }
    
}



/*
    URL Params
*/
export const getUrlParams = (key) => {
    let params = new URLSearchParams(document.location.search).get(key);

    // params are not assigned OR empty
    if(params === null || params === '') return [];

    return params.replaceAll(' ', '').split(',');
}
export const formUrlParams = (updates) => {

    let urlParamStrings = [];
    /*
        Current params
    */
    // accesskey
    const accesskey = getUrlParams('key');
    if(Array.isArray(accesskey) && accesskey.length > 0) urlParamStrings.push(`key=${accesskey.join(",")}`);
    
    // filters
    let newFilters = getUrlParams('filters');
    if(Array.isArray(updates) && updates.filter(update => update['paramId'] === 'filters').length > 0){
        const filterUpdate = updates.filter(update => update['paramId'] === 'filters')[0];
        newFilters = newFilters.concat(filterUpdate['addParams']).filter(param => !filterUpdate['removeParams'].includes(param));
    }
    if(Array.isArray(newFilters) && newFilters.length > 0) urlParamStrings.push(`filters=${newFilters.join(",")}`);

    return urlParamStrings.length > 0 ? `?${urlParamStrings.join('&')}` : '';
}