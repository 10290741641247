import React from 'react';


const Gradation = (props) => {

    return <div className='gradation-container'>
        <div className={`bg bg3`} />
        <div className={`bg bg2`} />
        <div className={`bg bg1`} />
    </div>
}

export default Gradation;